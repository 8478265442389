import { Theme } from '@mui/material/styles/createTheme';

export const defaultChip = (theme: Theme) => {
  const chip = theme.customComponents.chip.default;
  return [
    {
      props: (props: { variant: string }) => props.variant === 'default',
      style: {
        backgroundColor: chip.backgroundColor,
        border: chip.border,
        svg: {
          fill: chip.color,
        },
        '& .MuiChip-label': {
          color: chip.color,
        },
        '& .MuiChip-icon': {
          color: chip.color,
        },
        '&:hover': {
          backgroundColor: chip.hover?.backgroundColor,
          color: chip.hover?.color,
        },
        '&:focus': {
          backgroundColor: chip.focus?.backgroundColor,
          boxShadow: chip.focus?.boxShadow,
        },
        // '&.Mui-disabled': {
        //   backgroundColor: neutral[20],
        //   borderBottom: `1px solid ${neutral[30]}`,
        //   '& .MuiChip-label': {
        //     color: neutral[60],
        //   },
        //   '& .MuiChip-icon': {
        //     color: neutral[60],
        //   },
        // },
      },
    },
  ];
};
