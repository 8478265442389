import Product from 'components/Product';
import dateToString from 'utils/decorators/dateToString';
import titelize from 'utils/decorators/titelize';
import { ProductStatus, ProductType, Token } from 'utils/types/product';
import { TableCellContent } from 'pages/Ledger/NavReviewPage/components/styles';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';
import { Button } from 'components/Button/Button';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledActionsTableCell } from 'shared/Tables/table.styles';

interface ProductsTableRowProps {
  productType: ProductType;
  product?: Token;
  editAction?: () => void;
  deleteAction?: () => void;
  archiveAction?: () => void;
  activateAction?: (createSeedOrder?: Boolean) => void;
  reactivateAction?: () => void;
  showActionsCell?: boolean;
}

export const TokenTableRow = ({
  product,
  editAction,
  deleteAction,
  archiveAction,
  activateAction,
  showActionsCell = true,
}: ProductsTableRowProps) => {
  return (
    <MuiStyledTableRow
      data-qa-id={`${product?.name}-row`}
      key={product?._id}
      onClick={
        product?.status === ProductStatus.DELETED || product?._actions?.update
          ? editAction
          : undefined
      }
    >
      <MuiStyledTableCell data-qa-id={`${product?.name}-ticker`}>
        <TableCellContent>
          <Product token={product as Token} />
        </TableCellContent>
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${product?.name}-name`}>{product?.name}</MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${product?.name}-standard`}>
        {product?.tokenStandard}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${product?.name}-chain`}>
        {product && titelize(product?.destinationChain)}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${product?.name}-updated`}>
        {product && dateToString(product?.updatedAt)}
      </MuiStyledTableCell>
      <MuiStyledActionsTableCell
        data-qa-id="actionBtn"
        onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
          event.stopPropagation()
        }
      >
        {showActionsCell &&
          (product?.status === ProductStatus.DELETED ||
          product?.status === ProductStatus.PENDING ? (
            <>
              {product?._actions?.archive && (
                <TablePopoverMenu id={product._id} className="actionsCell">
                  <Button
                    data-qa-id="resendInvitation"
                    fullWidth
                    variant="inverse"
                    onClick={archiveAction}
                  >
                    Archive
                  </Button>
                </TablePopoverMenu>
              )}
            </>
          ) : (
            <>
              {(product?._actions?.activate || product?._actions?.delete) &&
                product?.status !== ProductStatus.ARCHIVED && (
                  <TablePopoverMenu id={product._id} className="actionsCell">
                    {product?._actions?.activate && product?.status === ProductStatus.IN_REVIEW && (
                      <Button
                        data-qa-id="resendInvitation"
                        fullWidth
                        variant="inverse"
                        onClick={() => activateAction && activateAction()}
                      >
                        Activate
                      </Button>
                    )}
                    {product?._actions?.delete && (
                      <Button
                        data-qa-id="resendInvitation"
                        fullWidth
                        variant="inverse"
                        onClick={deleteAction}
                      >
                        Deactivate
                      </Button>
                    )}
                  </TablePopoverMenu>
                )}
            </>
          ))}
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};
