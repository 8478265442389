/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Fragment } from 'react';
import { capitalize, groupBy } from 'lodash';
import {
  CUSTODIAN_INFO_STEP,
  ETPPartnerDetailsStepType,
  PARTNERS_STEP,
  WALLETS_INFO_STEP,
} from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';
import { Button } from 'components/Button/Button';
import Table from 'components/Table';
import { ReactComponent as PencilIcon } from 'assets/pencil-alt.svg';
import { useUserPermissions } from 'store/user/selectors';
import useOnSubmit from 'pages/Instruments/hooks/useOnSubmit';
import { useCurrentInstrument, useCurrentInstrumentFormData } from 'store/instruments/selectors';
import { InstrumentFormDataProps } from 'pages/Instruments/Instruments.types';
import { mapPartners } from 'pages/Instruments/components/Form/PartnerDetails/components/ReviewStep/helpers';
import { StyledEntityName } from './ReviewStep.styles';
import { IconButton, Stack, Typography } from '@mui/material';
import {
  MuiStyledRowTitle,
  MuiStyledTableHeader,
  MuiStyledTableRow,
} from 'components/Table/TableRow';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { ReviewStepColumnsConfig } from './ReviewStepColumnsConfig';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { StyledButtonRow } from 'pages/Tokens/components/Form/TokenDetails/components/SummaryStep/SummaryStep.styles';
import { StyledColumn } from 'pages/Tokens/components/Form/PartnerDetails/components/ReviewStep/ReviewStep.styles';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import { useCompaniesSuggestion } from 'utils/api/hooks/useCompaniesSuggestion.hook';

export interface ReviewStepProps {
  goBack: () => void;
  goToStep: (step: ETPPartnerDetailsStepType) => void;
}

function ReviewStep({ goBack, goToStep }: ReviewStepProps) {
  const { data: partners } = useCompaniesSuggestion();

  const permissions = useUserPermissions();
  const { instrument } = useCurrentInstrument();
  const instrumentFormsData = useCurrentInstrumentFormData() as InstrumentFormDataProps;
  const actions = instrument?._actions;
  const { onActivateInstrument, onSubmitForApproval, onCreateSeedOrder } = useOnSubmit(instrument);

  const wallets = [
    ...(instrumentFormsData[WALLETS_INFO_STEP]?.custodianWallets || []),
    ...(instrumentFormsData[WALLETS_INFO_STEP]?.unifiedWallets || []),
  ];

  return (
    <>
      <MuiStyledTableHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="Partners" />
          <IconButton onClick={() => goToStep(PARTNERS_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </MuiStyledTableHeader>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBodyWithStates
          hasContent={Boolean(instrumentFormsData)}
          noContentLabel="No data at the moment."
        >
          <MuiStyledTableRow>
            <MuiStyledTableCell>Issuer</MuiStyledTableCell>
            <MuiStyledTableCell>
              {
                partners?.find(
                  (partner) => partner._id === instrumentFormsData[PARTNERS_STEP]?.issuer
                )?.name
              }
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell>Authorized Participants</MuiStyledTableCell>
            <MuiStyledTableCell>
              {mapPartners(
                instrumentFormsData[PARTNERS_STEP]?.authorizedParticipants,
                partners ?? []
              )?.map((name) => (
                <StyledEntityName key={name}>{name};</StyledEntityName>
              ))}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell>Calculation Agents</MuiStyledTableCell>
            <MuiStyledTableCell>
              {mapPartners(
                instrumentFormsData[PARTNERS_STEP]?.calculationAgents,
                partners ?? []
              )?.map((name) => (
                <StyledEntityName key={name}>{name};</StyledEntityName>
              ))}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell>PCF Uploaders</MuiStyledTableCell>
            <MuiStyledTableCell>
              {mapPartners(instrumentFormsData[PARTNERS_STEP]?.pcfUploaders, partners ?? [])?.map(
                (name) => (
                  <StyledEntityName key={name}>{name};</StyledEntityName>
                )
              )}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell>Extra Providers</MuiStyledTableCell>
            <MuiStyledTableCell>
              {instrumentFormsData[PARTNERS_STEP]?.custodians?.map(capitalize)?.map((name) => (
                <StyledEntityName key={name}>{name};</StyledEntityName>
              ))}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
        </TableBodyWithStates>
      </Table>
      <MuiStyledTableHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="Custodian Info" />
          <IconButton onClick={() => goToStep(CUSTODIAN_INFO_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </MuiStyledTableHeader>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBodyWithStates
          hasContent={Boolean(instrumentFormsData[CUSTODIAN_INFO_STEP]?.length)}
          noContentLabel="No data at the moment."
        >
          <MuiStyledTableRow>
            <MuiStyledTableCell>Custodians Accounts</MuiStyledTableCell>
            <MuiStyledTableCell>
              {instrumentFormsData[CUSTODIAN_INFO_STEP]?.map((custodian) => (
                <StyledEntityName key={custodian._id}>{custodian.name};</StyledEntityName>
              ))}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
        </TableBodyWithStates>
      </Table>
      <MuiStyledTableHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="Wallets Info" />
          <IconButton onClick={() => goToStep(WALLETS_INFO_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </MuiStyledTableHeader>
      {Object.entries(groupBy(wallets, 'chain')).map(([chain, walletsInfo], index) => (
        <Fragment key={chain}>
          <MuiStyledRowTitle>
            <Typography variant="labelLargeStrong">{chain} Wallets</Typography>
          </MuiStyledRowTitle>
          <Table>
            <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
            <TableBodyWithStates
              hasContent={Boolean(walletsInfo.length)}
              noContentLabel="No data at the moment."
            >
              <MuiStyledTableRow>
                <MuiStyledTableCell>Wallet</MuiStyledTableCell>
                <MuiStyledTableCell>
                  {walletsInfo.map((wallet) => (
                    <StyledEntityName key={wallet._id}>{wallet?.description};</StyledEntityName>
                  ))}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            </TableBodyWithStates>
          </Table>
        </Fragment>
      ))}
      <StyledButtonRow>
        <StyledColumn cols={2}>
          <Button fullWidth type="button" variant="secondary" onClick={goBack}>
            Back
          </Button>
        </StyledColumn>
        {Boolean(permissions?.canEditEtp && actions?.submitForApproval) && (
          <StyledColumn cols={2}>
            <Button fullWidth type="button" variant="primary" onClick={onSubmitForApproval}>
              Ready for Seed
            </Button>
          </StyledColumn>
        )}
        {Boolean(
          permissions?.canApproveEtp && actions?.activate && !actions?.submitForApproval
        ) && (
          <>
            <StyledColumn cols={2}>
              <Button fullWidth type="button" variant="primary" onClick={onCreateSeedOrder}>
                Create Seed Order
              </Button>
            </StyledColumn>
            <StyledColumn cols={2}>
              <Button fullWidth type="button" variant="primary" onClick={onActivateInstrument}>
                Activate
              </Button>
            </StyledColumn>
          </>
        )}
      </StyledButtonRow>
    </>
  );
}

export default ReviewStep;
