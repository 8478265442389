import {
  StyledCard,
  StyledConstituentModalLabel,
} from 'pages/Instruments/components/Form/EtpDetails/components/ConstituentStep/components/ConstituentStep.styles';
import useAppDispatch from 'hooks/useAppDispatch';
import { CONSTITUENT_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { ConstituentAssetType, ProductConstituentAssetType } from 'utils/types/product';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { NewRebalanceConstituent } from './NewRebalanceConstituent';
import { PartialInstrumentFormProps } from 'pages/Instruments/Instruments.types';
import { RebalanceWithConstitentsDetails } from 'utils/types/rebalance';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { postAssets } from 'utils/api/assets';
import { saveInstrumentApiCall } from 'utils/api/instruments';
import { useMutation } from 'react-query';
import { useProductData } from 'shared/Modals/NavReviewModals/hook/useProductData';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

export const AddConstituentsModal = ({ closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const rebalanceData = data.data as RebalanceWithConstitentsDetails;
  const productId = rebalanceData.rebalance.product._id;
  const { data: product, isLoading: isLoadingProduct } = useProductData(productId);
  const newConstituent = rebalanceData.constituent;

  const createPostAssetMutation = useMutation({
    mutationFn: (constituent: ConstituentAssetType) => {
      return postAssets({
        coingeckoId: constituent.coingeckoId,
        ticker: constituent.ticker,
        name: constituent.name,
        rounding: constituent.rounding,
        type: constituent.assetType,
      });
    },
    onSuccess: async (res, constituent) => {
      const updatedConstituentsList = product?.constituentAssets?.length
        ? [...product?.constituentAssets, constituent]
        : [constituent];
      etpMutation.mutate({
        [CONSTITUENT_STEP]: updatedConstituentsList as ProductConstituentAssetType[],
      });
    },
    onError: (err: Error) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
  });

  const etpMutation = useMutation({
    mutationFn: (instrumentProps: PartialInstrumentFormProps) => {
      return saveInstrumentApiCall(product?._actions?.update?.uri ?? '', instrumentProps);
    },
    onSuccess: (res) => {
      dispatch(
        createNotification({
          message: `Product ${product?.ticker} has been updated successfully`,
          title: 'Product Updated',
          type: 'success',
        })
      );
      closeModal();
    },
    onError: (err: Error) => {
      dispatch(
        createNotification(
          {
            message: `Error ${err.message ?? ''}`,
            title: 'Asset not added',
            type: 'error',
          },
          err
        )
      );
    },
  });

  const handleSubmit = (newConstituent: ConstituentAssetType) => {
    return newConstituent && createPostAssetMutation.mutate(newConstituent);
  };

  return (
    <CustomModal open onCloseModal={closeModal}>
      <StyledCard
        header={<CardHeaderTitleWithLabel label="Constituent" title="Add Assets" />}
        onClose={closeModal}
      >
        <StyledConstituentModalLabel>
          You can add existing cryptos or create new crypto assets.
        </StyledConstituentModalLabel>
        <NewRebalanceConstituent
          onSubmit={(data) => handleSubmit(data)}
          selectedConstituent={newConstituent}
          toggleModal={closeModal}
          isLoading={etpMutation.isLoading || createPostAssetMutation.isLoading || isLoadingProduct}
        />
      </StyledCard>
    </CustomModal>
  );
};
