import { Button } from 'components/Button/Button';
import { Box, Stack, TableBody, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { ReactComponent as InfoSvg } from 'assets/info.svg';
import Table from 'components/Table';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import copyToClipboard from 'utils/copyToClipboard';
import { MuiStyledSectionHeader } from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import Tooltip from 'components/Tooltip';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import { TableHeaderColumn } from 'shared/Tables/Table.types';
import { WalletInfo, Order, ProductType } from 'utils/types';
import Tag from 'components/Tag/Tag';

const AuthorizedMerchantColumnsConfig = [
  { label: 'AM Wallets', propName: 'apWallets', propType: 'string', width: '80%' },
  { label: '', propName: 'actions', propType: 'actions', width: '20%' },
];

const AuthorizedParticipantColumnsConfig = [
  { label: 'AP Wallets', propName: 'apWallets', propType: 'string', width: '80%' },
  { label: '', propName: 'actions', propType: 'actions', width: '20%' },
];

const WalletsIssuerColumnsConfig = [
  { label: 'Issuer Wallets', propName: 'issuerWallets', propType: 'string', width: '80%' },
  { label: '', propName: 'actions', propType: 'actions', width: '20%' },
];

interface WalletsInformationProps {
  productType: ProductType;
  className?: string;
  order?: Order;
}

const WalletsInformation = ({ productType, className, order }: WalletsInformationProps) => {
  const showWallets =
    Boolean(order?.issuerWallets?.length) || Boolean(order?.authorizedParticipantWallets?.length);
  if (isEmpty(order?.product) || !showWallets) return null;

  return (
    <section className={className}>
      <MuiStyledSectionHeader>
        <Typography variant="subheadingMedium">Wallets Information</Typography>
      </MuiStyledSectionHeader>
      {Boolean(order?.authorizedParticipantWallets?.length) && (
        <WalletTable
          wallets={order?.authorizedParticipantWallets}
          columns={
            productType === 'ETP'
              ? AuthorizedParticipantColumnsConfig
              : AuthorizedMerchantColumnsConfig
          }
          isEtpWallet={productType === 'ETP'}
        />
      )}
      {Boolean(order?.issuerWallets?.length) && (
        <WalletTable
          isEtpWallet={productType === 'ETP'}
          wallets={order?.issuerWallets}
          columns={WalletsIssuerColumnsConfig}
        />
      )}
    </section>
  );
};

export default WalletsInformation;

const WalletTable = ({
  isEtpWallet,
  columns,
  wallets,
}: {
  isEtpWallet?: boolean;
  columns: TableHeaderColumn[];
  wallets?: WalletInfo[];
}) => {
  return (
    <Table noPadding={true}>
      <TableHeaderWithMultiSort columns={columns} />
      <TableBody>
        {wallets?.map((wallet: WalletInfo) => (
          <MuiStyledTableRow key={`wallet-${wallet.ticker}`}>
            <MuiStyledTableCell>
              <Stack alignItems="center" direction="row" gap={1}>
                <Tag label={wallet.ticker} size="small" variant="inverse" />
                <Typography variant={'bodyMediumStrong'}>{wallet.address}</Typography>
                {!isEtpWallet && (
                  <Tooltip
                    placement="top"
                    title={<Box sx={{ padding: 1, color: 'white' }}>{wallet.description}</Box>}
                  >
                    <InfoSvg
                      fontSize={12}
                      fill="black"
                      style={{ alignItems: 'center', display: 'flex' }}
                    />
                  </Tooltip>
                )}
                {wallet.purpose && <Tag label={wallet.purpose} size="small" variant="default" />}
              </Stack>
            </MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              <Button
                data-html2canvas-ignore
                variant="inverse"
                onClick={() => wallet.address && copyToClipboard(wallet.address)}
              >
                <CopyIcon />
              </Button>
            </MuiStyledTableCell>
          </MuiStyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
};
