import { StyledConstituentModalLabel } from 'pages/Instruments/components/Form/EtpDetails/components/ConstituentStep/components/ConstituentStep.styles';
import { Button } from 'components/Button/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { DirtyInstrumentType } from 'store/modals/types';
import {
  BENCHMARK_STEP,
  FACTSHEET_STEP,
  GENERAL_DETAILS_STEP,
  MARKET_STEP,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { PARTNERS_STEP } from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';
import { BenchmarkDifferencesSection } from 'shared/Modals/TouchedProductModal/InstrumentTouchedDataModal/ActiveETPDifferencesSections/BenchmarkDifferencesSection';
import { GeneralDetailsDifferencesSection } from 'shared/Modals/TouchedProductModal/InstrumentTouchedDataModal/ActiveETPDifferencesSections/GeneralDetailsDifferencesSection';
import { InstrumentFormDataStepType } from 'pages/Instruments/Instruments.types';
import { MarketDifferencesSection } from 'shared/Modals/TouchedProductModal/InstrumentTouchedDataModal/ActiveETPDifferencesSections/MarketDifferencesSection';
import { PartnerDetailsDifferencesSection } from 'shared/Modals/TouchedProductModal/InstrumentTouchedDataModal/ActiveETPDifferencesSections/PartnerDetailsDifferencesSection';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import {
  useActiveStep,
  useDirtyInstrumentFormData,
  useFormDataDirtyFields,
} from 'store/instruments/selectors';
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import { SaveProductDraftOptions } from 'utils/types';
import { useMemo } from 'react';
import { FactsheetDifferencesSection } from 'shared/Modals/TouchedProductModal/InstrumentTouchedDataModal/ActiveETPDifferencesSections/FactsheetDifferencesSection';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { Box, Stack } from '@mui/material';

export interface DirtyField {
  propName: string;
  propLabel: string;
  prevValue: string;
  currentValue: string;
}

export const InstrumentTouchedDataModal = ({
  closeModal,
  data,
  onCloseModalAction,
}: IModalWithData) => {
  const dispatch = useAppDispatch();
  const activeStep = useActiveStep() ?? GENERAL_DETAILS_STEP;
  const touchedActiveInstrumentFormData = useDirtyInstrumentFormData(activeStep);
  const targetStep = (data.data as DirtyInstrumentType)?.targetStep;
  const formDataDirtyFields = useFormDataDirtyFields(activeStep);
  const { saveInstrumentToRemoteAndStore } = useSaveETPHook();
  const stepOptions: SaveProductDraftOptions | undefined = useMemo(() => {
    if (activeStep === PARTNERS_STEP) {
      return {
        loadCustodians: true,
        loadWallets: true,
      };
    }
  }, [activeStep]);
  const saveValues = async () => {
    return saveInstrumentToRemoteAndStore(
      {
        [activeStep as string]: touchedActiveInstrumentFormData as {},
      },
      stepOptions
    );
  };

  const onCancelButton = () => {
    closeModal();
  };

  const onSubmit = () => {
    saveValues()
      .then(() => {
        if (onCloseModalAction) onCloseModalAction(targetStep);
        closeModal();
      })
      .catch((error) =>
        dispatch(createNotification(errorNotification(error.message, 'Error'), error))
      );
  };

  function getActiveStepDifferencesSection(activeStep?: InstrumentFormDataStepType) {
    switch (activeStep) {
      case GENERAL_DETAILS_STEP:
        return <GeneralDetailsDifferencesSection />;
      case FACTSHEET_STEP:
        return <FactsheetDifferencesSection />;
      case MARKET_STEP:
        return <MarketDifferencesSection />;
      case BENCHMARK_STEP:
        return <BenchmarkDifferencesSection />;
      case PARTNERS_STEP:
        return <PartnerDetailsDifferencesSection />;
      default:
        return <GeneralDetailsDifferencesSection />;
    }
  }

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        header={<CardHeaderTitleWithLabel label="ETP" title="Update Active ETP" />}
        body={
          <Stack padding={2}>
            <StyledConstituentModalLabel>
              {formDataDirtyFields.length
                ? 'You have made following changes within this step:'
                : 'You removed all the changes you have made, do you want to continue?'}
            </StyledConstituentModalLabel>
            {getActiveStepDifferencesSection(activeStep)}
          </Stack>
        }
        footer={
          <Box padding={2}>
            <MuiStyledModalFooterButtons>
              <Button variant="primary" onClick={onSubmit} fullWidth>
                {formDataDirtyFields.length ? 'Confirm and Save' : 'Confirm'}
              </Button>
              <Button variant="secondary" onClick={onCancelButton} fullWidth>
                Cancel
              </Button>
            </MuiStyledModalFooterButtons>
          </Box>
        }
        onClose={closeModal}
      ></Card>
    </CustomModal>
  );
};
