import Button from 'components/Button';
import { TableCell } from 'components/Table';
import { Box, styled as MuiStyled, Table } from '@mui/material';
import fontTypes from 'utils/theme/designTokens/fontTypes';
import { Card } from 'components/Card/Card';

interface ColoredItemProps {
  color?: 'main' | 'success' | 'error';
}

export const StyledRebalancePageWrapper = MuiStyled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: white;
  padding: 16px;
  margin-bottom: 24px;
  min-width: min-content;
`;

export const StyledRebalancePageBody = MuiStyled(Box)`
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
  gap: 20px;
`;

export const StyledNoContent = MuiStyled(Box)`
  display: flex;
  justify-content: center;
  width: auto;
  height: 180px;
  margin: auto;
  flex-direction: column;
  gap: 16px;
  svg {
    align-self: center;
  }
`;

export const StyledRebalanceTradesCard = MuiStyled(Box)`
  background: white;
  display: flex;
  flex-direction: column;
  border: 1px #e6e6e6 solid;
`;

export const StyledRebalanceActionBarWrapper = MuiStyled(Box)`
  border-radius: 8px;
  width: 100%;
  min-height: 56px;
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  background: white;
  gap: 16px;
  padding: 8px 0;
  & > *:first-child {
    padding-left: 8px;
    min-width: 200px;
    align-content: center;
  }
  & > *:last-child {
    padding: 0 8px 0 0;
    width: unset;
    justify-content: flex-end;
    align-content: center;
  }
`;
export const StyledTradeTableWrapper = MuiStyled(Box)`
  height: 240px;
  overflow: auto;
  flex-grow: 2;
`;

export const StyledRebalancesHeader = MuiStyled(Box)`
  height: 90px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  background: white;
  padding-inline: 8px;
`;

export const StyledHeaderTitleComponent = MuiStyled(Box)`
  height: 26px;
  display: flex;
  width: 104px;
  gap: 8px;
  svg {
    align-self: center;
  }
`;

export const StyledHeaderNotes = MuiStyled(Box)`
  line-height: 16px;
  display: flex;
  height: 48px;
  padding: 16px 0;
  gap: 16px;
`;

export const StyledHeaderTitleWrapper = MuiStyled(Box)`
  line-height: 28px;
  height: 42px;
  padding-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledRebalancesCardFooterWrapper = MuiStyled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-inline: 16px;
  width: 50%;
`;

export const StyledRebalancesCardFooterButtonWrapper = MuiStyled(Box)`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 16px 0;
  height: 72px;
  button {
    width: 50%;
  }
`;

export const StyledColoredTableCell = MuiStyled(TableCell)<ColoredItemProps>`
  ${({ color, theme }) => color && { color: theme.palette.cellRange[color] }};
`;

export const StyledRebalancePageDetails = MuiStyled(Box)`
  gap: 32px;
`;

export const StyledRebalanceDetailsContainer = MuiStyled('section')`
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  overflow-y: auto;
  gap: 16px;
`;

export const StyledNotificationContainer = MuiStyled(Box)`
  margin-top: 16px;
  padding-inline: 16px;
`;

export const StyledButtonWrapper = MuiStyled(Box)`
  width: fit-content;
  padding: 16px;
  display: flex;
  gap: 8px;
`;

export const StyledDownloadXSLButton = MuiStyled(Button)`
  svg:not(:first-child) {
    margin-left: 1rem;
  }
`;

export const StyledTitle = MuiStyled(Box)`
  ${() => fontTypes['heading-03']};
  color: ${({ theme }) => theme.palette.secondary.light};
  margin-block: 8px;
`;

export const StyledFilterWrapper = MuiStyled(Box)`
  display: flex;
  justify-items: stretch;
  gap: 8px;
`;

export const StyledRebalanceTable = MuiStyled(Table)(({ theme }) => ({
  '.MuiTableCell-root': {
    padding: '0px 16px',
  },
}));
