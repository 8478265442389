import { StyledTableCellContentWithIcon } from 'shared/Tables/RegisterTable/RegisterTable.styles';
import { PortfolioComposition } from 'utils/types/pcfs';
import ProductIcon from 'components/ProductIcon';
import PageTitle from 'components/PageTitle';
import { getPCFState } from 'shared/Tables/PCFTable/PCFTableRow';
import { StyledTagWrapper } from 'pages/Ledger/PCF/components/styles';
import Tag from 'components/Tag/Tag';

interface PCFDetailsHeaderProps {
  pcf: PortfolioComposition | undefined;
}

export const PCFDetailsPageHeader = ({ pcf }: PCFDetailsHeaderProps) => {
  return (
    <StyledTableCellContentWithIcon>
      {pcf && (
        <>
          <ProductIcon
            className="product-icon"
            iconUrl={pcf?.product.fundIcon}
            ticker={pcf?.product.ticker}
            size="40px"
          />
          <PageTitle>{pcf?.product.ticker} PCF Details</PageTitle>
          <StyledTagWrapper>
            <Tag
              label={getPCFState(pcf!.status, pcf!.isPendingUpload)[0]}
              variant={getPCFState(pcf!.status, pcf!.isPendingUpload)[1]}
              size="small"
            />
          </StyledTagWrapper>
        </>
      )}
    </StyledTableCellContentWithIcon>
  );
};
