import React from 'react';
import { Box, Chip } from '@mui/material';
import { ReactComponent as Close } from 'assets/close.svg';
import { variantTypes } from 'components/Tag/Tag.types';
import { ReactComponent as ArrowDown } from 'assets/chevron-down.svg';

interface TagProps {
  disabled?: boolean;
  closeIcon?: boolean;
  expandable?: boolean;
  label: string;
  variant?: variantTypes;
  onClose?: () => void;
  size?: 'small' | 'medium';
  [key: string]: any;
  fullWidth?: Boolean;
  isExpanded?: Boolean;
}

const Tag: React.FC<TagProps> = ({
  variant = 'default',
  disabled = false,
  closeIcon = false,
  expandable = false,
  label = '',
  onClose = () => {},
  size = 'medium',
  fullWidth,
  isExpanded,
  ...props
}) => {
  // @TODO useTheme
  const borderBottomColor = {
    yellow: '#FBEED2',
    inverse: '#242C33',
    green: '#B0E8BD',
    red: '#FFCCCC',
    blue: '#CEE1FD',
    default: '#D2DBE4',
  };
  const hasIcon = expandable || closeIcon;
  return (
    <Box sx={{ position: 'relative', display: 'inline-block', width: fullWidth ? '100%' : 'auto' }}>
      <Chip
        sx={{
          cursor: expandable ? 'pointer' : 'default',
          width: 'inherit',
          borderBottom: `1.25px solid ${
            disabled ? borderBottomColor['default'] : borderBottomColor[variant]
          }`,
          '& > svg': {
            ...(expandable && isExpanded ? { transform: 'rotate(180deg)' } : {}),
          },
          ...(!(hasIcon && !disabled) && { padding: '8px 12px' }),
        }}
        label={label}
        onDelete={closeIcon && !disabled ? onClose : undefined}
        deleteIcon={closeIcon && !disabled ? <Close height={8} width={8} /> : undefined}
        icon={
          expandable ? (
            <ArrowDown style={{ height: '14px', marginTop: '2px', marginInline: '0' }} />
          ) : undefined
        }
        disabled={disabled}
        variant={variant}
        size={size}
        {...props}
      />
    </Box>
  );
};

export default Tag;
