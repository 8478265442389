/* eslint-disable complexity */
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledActionsTableCell } from 'shared/Tables/table.styles';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';
import { Button } from 'components/Button/Button';
import {
  CompanyCustodianWallet,
  ProductCustodianWallet,
  WalletUnionType,
} from 'utils/types/wallets';
import Tooltip from 'components/Tooltip';
import { StyledUnderlinedDiv, StyledFullWalletAddress } from './WalletsTable.styles';

const THRESHOLD = 25;
const WALLET_ADDRESS_COLUMN_MAX_LENGTH = 30;

function condenseWalletAddress(
  inputString: string,
  threshold = THRESHOLD,
  maxLength = WALLET_ADDRESS_COLUMN_MAX_LENGTH
) {
  if (inputString.length < maxLength) {
    return inputString;
  }

  const regex = new RegExp(`[^\\s]{${threshold},}`);

  if (regex.test(inputString)) {
    const truncatedString = inputString.substring(0, 6) + '...' + inputString.slice(-4);
    return (
      <Tooltip
        title={
          <StyledFullWalletAddress sx={{ padding: 1 }} onClick={(event) => event.stopPropagation()}>
            {inputString}
          </StyledFullWalletAddress>
        }
        placement="top"
      >
        <StyledUnderlinedDiv>{truncatedString}</StyledUnderlinedDiv>
      </Tooltip>
    );
  } else {
    return inputString;
  }
}

type Props = {
  wallet: WalletUnionType;
  editAction: () => void;
  deleteAction: () => void;
  activateAction: () => void;
  populateProductsRow?: boolean;
};

export const WalletsTableRow = ({
  wallet,
  editAction,
  deleteAction,
  activateAction,
  populateProductsRow,
}: Props) => {
  //* function to truncate wallet address. truncates address with 25+ consecutive charcters w/o whitespace. if total address length is 40 chars or less, returns it as is
  const revisedWalletAddress = condenseWalletAddress(wallet.address);

  return (
    <MuiStyledTableRow
      data-qa-id={`row-${wallet.description}`}
      key={wallet._id}
      onClick={editAction}
    >
      <MuiStyledTableCell data-qa-id={`wallet-address-${wallet.address}`}>
        {revisedWalletAddress}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`wallet-chain-${wallet.chain}`}>
        {wallet.chain}
      </MuiStyledTableCell>
      <MuiStyledTableCell
        data-qa-id={`wallet-description-${wallet.description}`}
        colSpan={populateProductsRow ? 1 : 2}
      >
        {wallet.description}
      </MuiStyledTableCell>
      {populateProductsRow && (
        <MuiStyledTableCell>
          {(wallet as CompanyCustodianWallet | ProductCustodianWallet)?.productName ?? ''}
        </MuiStyledTableCell>
      )}
      <MuiStyledActionsTableCell data-qa-id="actionBtn">
        {wallet._actions && Object.keys(wallet._actions) && (
          <TablePopoverMenu id={wallet._id ?? ''} className="actionsCell">
            <ul>
              {wallet._actions?.update && (
                <Button data-qa-id="editWallet" fullWidth variant="inverse" onClick={editAction}>
                  Edit wallet
                </Button>
              )}
              {wallet._actions?.activate && (
                <Button
                  data-qa-id="activateWallet"
                  fullWidth
                  variant="inverse"
                  onClick={activateAction}
                >
                  Activate wallet
                </Button>
              )}
              {wallet._actions?.delete && (
                <Button data-qa-id="deleteWallet" fullWidth variant="danger" onClick={deleteAction}>
                  Deactivate wallet
                </Button>
              )}
            </ul>
          </TablePopoverMenu>
        )}
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};
