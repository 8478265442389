import { PortfolioComposition } from 'utils/types/pcfs';
import { renderValueOrDash } from 'utils/formatting';
import Table from 'components/Table';
import { MuiStyledFxRate } from 'pages/Ledger/PCF/components/PCFStyles';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { PCFDetailsListColumnConfig } from 'pages/Ledger/PCF/PCFDetails/PCFDetailsAP/tableColumnsConfig';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { Card } from 'components/Card/Card';
import { Box, Typography } from '@mui/material';

export interface PCFFileInfoProps {
  pcf?: PortfolioComposition;
  isLoading?: boolean;
}

function PCFFileInfo({ pcf, isLoading }: PCFFileInfoProps) {
  return (
    <Card
      header={
        <Box padding={'16px'}>
          <Typography variant="subheadingMedium" sx={{ wordBreak: 'break-word' }}>
            Portfolio Composition File
          </Typography>
        </Box>
      }
      body={
        <Table noPadding>
          <TableHeaderWithMultiSort columns={PCFDetailsListColumnConfig} />
          <TableBodyWithStates
            loadingData={isLoading}
            hasContent={!!pcf}
            noContentLabel="No PCF data."
          >
            <PCFEtpFileInfoRow pcf={pcf} />
          </TableBodyWithStates>
        </Table>
      }
    />
  );
}

export default PCFFileInfo;

const PCFEtpFileInfoRow = ({ pcf }: { pcf?: PortfolioComposition }) => {
  return (
    <>
      <MuiStyledTableRow>
        <MuiStyledTableCell>Valuation Date</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.valuationDate}</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.valuationDate}</MuiStyledTableCell>
      </MuiStyledTableRow>
      <MuiStyledTableRow>
        <MuiStyledTableCell>Effective Date</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.effectiveDate}</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.effectiveDate}</MuiStyledTableCell>
      </MuiStyledTableRow>
      <MuiStyledTableRow>
        <MuiStyledTableCell>Settlement Date</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.settlementDate}</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.settlementDate}</MuiStyledTableCell>
      </MuiStyledTableRow>
      {pcf?.currencyPrices?.map((currencyPrice) => (
        <MuiStyledTableRow key={`totalProjectedNavPerUnit${currencyPrice.baseCurrency}`}>
          <MuiStyledTableCell>
            Projected NAV per Share {currencyPrice.baseCurrency}
          </MuiStyledTableCell>
          <MuiStyledTableCell
            error={
              pcf.errors?.totalProjectedNavPerUnit?.errors?.find(
                (error) => error.currency === currencyPrice.baseCurrency
              )
                ? 'error'
                : ''
            }
          >
            {renderValueOrDash(
              pcf?.synthetic?.totalProjectedNavPerUnit?.find(
                (total) => total.currency === currencyPrice.baseCurrency
              )?.value
            )}
            {currencyPrice.price && (
              <MuiStyledFxRate variant="body2">(fx rate: {currencyPrice.price})</MuiStyledFxRate>
            )}
          </MuiStyledTableCell>
          <MuiStyledTableCell
            error={
              pcf.errors?.totalProjectedNavPerUnit?.errors?.find(
                (error) => error.currency === currencyPrice.baseCurrency
              )
                ? 'error'
                : ''
            }
          >
            {renderValueOrDash(
              pcf?.official?.totalProjectedNavPerUnit?.find(
                (total) => total.currency === currencyPrice.baseCurrency
              )?.value
            )}
            {pcf?.official?.totalProjectedNavPerUnitFxRates?.find(
              (total) => total.baseCurrency === currencyPrice.baseCurrency
            ) && (
              <MuiStyledFxRate variant="body2">
                (fx rate:{' '}
                {renderValueOrDash(
                  pcf?.official?.totalProjectedNavPerUnitFxRates?.find(
                    (total) => total.baseCurrency === currencyPrice.baseCurrency
                  )?.price
                )}
                )
              </MuiStyledFxRate>
            )}
          </MuiStyledTableCell>
        </MuiStyledTableRow>
      ))}
      {pcf?.currencyPrices?.map((currencyPrice) => (
        <MuiStyledTableRow key={`totalProjectedNavCreationUnit${currencyPrice.baseCurrency}`}>
          <MuiStyledTableCell>
            Projected NAV per Creation Unit {currencyPrice.baseCurrency}
          </MuiStyledTableCell>
          <MuiStyledTableCell
            error={
              pcf.errors?.totalProjectedNavCreationUnit?.errors?.find(
                (error) => error.currency === currencyPrice.baseCurrency
              )
                ? 'error'
                : ''
            }
          >
            {renderValueOrDash(
              pcf?.synthetic?.totalProjectedNavCreationUnit?.find(
                (total) => total.currency === currencyPrice.baseCurrency
              )?.value
            )}
            {currencyPrice.price && (
              <MuiStyledFxRate variant="body2">(fx rate: {currencyPrice.price})</MuiStyledFxRate>
            )}
          </MuiStyledTableCell>
          <MuiStyledTableCell
            error={
              pcf.errors?.totalProjectedNavCreationUnit?.errors?.find(
                (error) => error.currency === currencyPrice.baseCurrency
              )
                ? 'error'
                : ''
            }
          >
            {renderValueOrDash(
              pcf?.official?.totalProjectedNavCreationUnit?.find(
                (total) => total.currency === currencyPrice.baseCurrency
              )?.value
            )}
            {pcf?.official?.totalProjectedNavCreationUnitFxRates?.find(
              (total) => total.baseCurrency === currencyPrice.baseCurrency
            ) && (
              <MuiStyledFxRate variant="body2">
                (fx rate:{' '}
                {renderValueOrDash(
                  pcf?.official?.totalProjectedNavCreationUnitFxRates?.find(
                    (total) => total.baseCurrency === currencyPrice.baseCurrency
                  )?.price
                )}
                )
              </MuiStyledFxRate>
            )}
          </MuiStyledTableCell>
        </MuiStyledTableRow>
      ))}
    </>
  );
};
