import useAppModal from 'hooks/useAppModal';
import { CALENDAR_MODAL, CALENDAR_EVENT_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { Calendar, CalendarEvent } from 'utils/types/calendar';
import { CalendarsTable } from 'shared/Tables/CalendarsTable/CalendarsTable';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { getCalendarsQuery } from 'utils/constants/reactQueries';
import { privateRoutesUrls } from 'router/constants';
import { queryClient } from 'utils/api/queries/queryClient';
import { useAuthenticatedUser, useUserPermissions } from 'store/user/selectors';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useCalendarsQuery } from 'pages/Admin/CalendarsPage/hooks/useCalendars';
import { useUrlParams } from 'hooks/useUrlParams';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { Card } from 'components/Card/Card';

export const CalendarsPage = () => {
  const openModal = useAppModal();
  const permissions = useUserPermissions();
  const loggedInUser = useAuthenticatedUser();
  const hasAccessToSystemCalendar = permissions?.canViewSystemCalendar;

  const { urlParams, setUrlParams } = useUrlParams(
    {
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_ITEMS_PER_PAGE,
    },
    privateRoutesUrls.dashboardRoutes.calendars
  );

  const { data, isLoading: loading } = useCalendarsQuery(
    loggedInUser.user?.organization.companyId,
    hasAccessToSystemCalendar,
    urlParams
  );
  const calendars = data?.calendars;
  const paginationResponse = data?.pagination ?? null;
  const pagination = useBackendPagination(paginationResponse, setUrlParams);
  const openCalendarModal = (type: MODAL_ACTIONS, calendarToEdit?: Calendar) => {
    openModal(
      {
        modalName: CALENDAR_MODAL,
        modalData: {
          data: calendarToEdit,
          companyData: {
            id: loggedInUser.user?.organization.companyId,
            name: loggedInUser.user?.organization.name,
          },
          isAdmin: hasAccessToSystemCalendar,
          type: type,
        },
      },
      {
        onCloseModalAction: () => {
          queryClient.invalidateQueries({ queryKey: [getCalendarsQuery] });
        },
      }
    );
  };

  const openCalendarEventModal = (
    type: MODAL_ACTIONS,
    calendarEventCalendar: Calendar,
    calendarEventToDelete?: CalendarEvent
  ) => {
    openModal(
      {
        modalName: CALENDAR_EVENT_MODAL,
        modalData: {
          data: calendarEventToDelete,
          calendarData: calendarEventCalendar,
          companyData: {
            id: loggedInUser.user?.organization.companyId,
            name: loggedInUser.user?.organization.name,
          },
          isAdmin: hasAccessToSystemCalendar,
          type: type,
        },
      },
      {
        onCloseModalAction: () => {
          queryClient.invalidateQueries({ queryKey: [getCalendarsQuery] });
        },
      }
    );
  };

  const createCalendarButton = permissions?.canEditSystemCalendar && (
    <StyledTableTopButton
      data-qa-id="createCalendarButton"
      variant="interactive"
      size="medium"
      onClick={() => openCalendarModal(MODAL_ACTIONS.CREATE)}
    >
      <span>Create Calendar</span>
      <PlusIcon />
    </StyledTableTopButton>
  );

  return (
    <>
      <TitleArea title={'Calendars'} actionButton={createCalendarButton} />
      <Card
        body={
          <CalendarsTable
            urlParams={urlParams}
            setUrlParams={setUrlParams}
            tableProperties={{
              pagination,
              noPadding: true,
            }}
            calendars={calendars ?? []}
            loadingData={loading}
            editAction={(calendar) => openCalendarModal(MODAL_ACTIONS.EDIT, calendar)}
            deleteAction={(calendar) => openCalendarModal(MODAL_ACTIONS.DELETE, calendar)}
            subitemEditAction={(calendar, calendarEvent) =>
              openCalendarEventModal(
                calendarEvent ? MODAL_ACTIONS.EDIT : MODAL_ACTIONS.CREATE,
                calendar,
                calendarEvent
              )
            }
            subitemDeleteAction={(calendar, calendarEvent) =>
              openCalendarEventModal(MODAL_ACTIONS.DELETE, calendar, calendarEvent)
            }
          />
        }
      />
    </>
  );
};
