import { Button } from 'components/Button/Button';
import Product from 'components/Product';
import {
  OfficialPortfolioCompositionConstituent,
  PortfolioComposition,
  PortfolioCompositionStatus,
} from 'utils/types/pcfs';
import { Checkbox, CheckboxProps } from 'components/BooleanInputs';
import { MuiStyledActionsTableCell } from 'shared/Tables/table.styles';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { PCF_STATE_LABEL } from 'utils/constants/pcf';
import { Stack } from '@mui/material';
import { TableHeaderColumn } from 'shared/Tables/Table.types';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';
import { TableShowMoreCell } from 'components/Table/TableShowMoreCell';
import { dailyTableDateFormat } from 'shared/Tables/table.utils';
import { format, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { variantTypes } from 'components/Tag/Tag.types';
import { StyledPCFCheckboxCell } from './PCFTable.styles';
import Tag from 'components/Tag/Tag';

type PatnerTableRowProps = {
  pcf: PortfolioComposition;
  editAction?: () => void;
  showActionsCell: boolean;
  checkboxProps?: CheckboxProps;
  columns: TableHeaderColumn[];
};

const renderDeliverables = (constituents: OfficialPortfolioCompositionConstituent[]) => {
  if (constituents.length > 1) {
    return (
      <TableShowMoreCell
        data-qa-id={`${constituents.map((constituent) => constituent?.ticker)}-deliverables`}
        items={
          constituents.map(
            (deliverable) => `${deliverable?.ticker}: ${deliverable?.amountPerCreationUnit}`
          ) ?? []
        }
      />
    );
  }

  const [constituent] = constituents;

  return (
    <MuiStyledTableCell data-qa-id={`${constituent?.ticker}-deliverables`}>
      {constituent?.amountPerCreationUnit ?? '0 deliverables'}
    </MuiStyledTableCell>
  );
};

export const getPCFState = (
  status: PortfolioCompositionStatus,
  isPendingUpload: boolean
): [string, variantTypes] => {
  switch (status) {
    case PortfolioCompositionStatus.PENDING:
      if (isPendingUpload) return [PCF_STATE_LABEL.PENDING_UPLOAD, 'red'];
      return [PCF_STATE_LABEL.PENDING_APPROVAL, 'blue'];

    case PortfolioCompositionStatus.APPROVED:
      return [PCF_STATE_LABEL.CONFIRMED, 'green'];
    default:
      return [status, 'yellow'];
  }
};

const getOfficialProjectedNAV = (pcf: PortfolioComposition) => {
  const baseCurrency = pcf.product.baseCurrency;
  return pcf.official?.totalNav?.find((totalNav) => totalNav.currency === baseCurrency)?.value;
};

const getOnyxProjectedNAV = (pcf: PortfolioComposition) => {
  const baseCurrency = pcf.product.baseCurrency;
  return pcf.synthetic?.totals?.find((totalNav) => totalNav.currency === baseCurrency)
    ?.netAssetValue;
};

const renderCell = (
  pcf: PortfolioComposition,
  officialProjectedNAV: string | undefined,
  onyxProjectedNAV: number | undefined,
  stateLabel: string,
  stateVariant: variantTypes,
  column: TableHeaderColumn
) => {
  switch (column.propName) {
    case 'product.ticker':
      return (
        <MuiStyledTableCell nowrap="true">
          <Stack direction="row" spacing={2}>
            <Product instrument={pcf.product} />
          </Stack>
        </MuiStyledTableCell>
      );
    case 'effectiveDate':
      return (
        <MuiStyledTableCell data-qa-id={`${pcf.effectiveDate}-effectiveDate`}>
          {pcf.effectiveDate && format(parseISO(pcf.effectiveDate), dailyTableDateFormat)}
        </MuiStyledTableCell>
      );
    case 'onyxProjectedNav':
      return (
        <MuiStyledTableCell data-qa-id={`${onyxProjectedNAV}-onyxProjectedNAV`}>
          {onyxProjectedNAV}
        </MuiStyledTableCell>
      );
    case 'officialProjectedNav':
      return (
        <MuiStyledTableCell data-qa-id={`${officialProjectedNAV}-officialProjectedNAV`}>
          {officialProjectedNAV}
        </MuiStyledTableCell>
      );
    case 'status':
      return (
        <MuiStyledTableCell data-qa-id={`${pcf.status}-status`}>
          {/* TODO - update the state variants */}
          <Tag label={stateLabel} variant={stateVariant} size="small" />
        </MuiStyledTableCell>
      );
    case 'deliverables':
      return renderDeliverables(pcf?.official?.constituents ?? []);
  }
};

const PartnerTableRow = ({
  pcf,
  editAction,
  showActionsCell = true,
  checkboxProps,
  columns,
}: PatnerTableRowProps) => {
  const [stateLabel, stateVariant] = getPCFState(pcf.status, pcf.isPendingUpload);

  const officialProjectedNAV = useMemo(() => getOfficialProjectedNAV(pcf), [pcf]);
  const onyxProjectedNAV = useMemo(() => getOnyxProjectedNAV(pcf), [pcf]);

  const openDocument = (url?: string) => {
    window.open(url, '_');
  };

  return (
    <MuiStyledTableRow data-qa-id={`${pcf._id}-row`} key={pcf._id} onClick={editAction}>
      {checkboxProps && (
        <StyledPCFCheckboxCell
          data-qa-id={`${pcf._id}-checkbox`}
          onClick={(event) => event.stopPropagation()}
        >
          <Checkbox {...checkboxProps} />
        </StyledPCFCheckboxCell>
      )}
      {columns
        .filter((col) => !col.hideColumn)
        .map((col) =>
          renderCell(pcf, officialProjectedNAV, onyxProjectedNAV, stateLabel, stateVariant, col)
        )}
      <MuiStyledActionsTableCell data-qa-id="actionsCell">
        {showActionsCell &&
          (pcf?.documents?.officialPdf?.url || pcf?.documents?.officialExcel?.url) && (
            <TablePopoverMenu data-qa-id="optionsButton" id={pcf._id} className="actionsCell">
              <ul>
                {pcf?.documents?.officialPdf?.url && (
                  <Button
                    fullWidth
                    variant="inverse"
                    onClick={() => openDocument(pcf?.documents?.officialPdf?.url)}
                    data-qa-id="downloadPDFButton"
                  >
                    Download PDF
                  </Button>
                )}
                {pcf?.documents?.officialExcel?.url && (
                  <Button
                    fullWidth
                    variant="inverse"
                    onClick={() => openDocument(pcf?.documents?.officialExcel?.url)}
                    data-qa-id="downloadXLSButton"
                  >
                    Download XLS
                  </Button>
                )}
              </ul>
            </TablePopoverMenu>
          )}
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};

export default PartnerTableRow;
