import { Button } from 'components/Button/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { GENERAL_DETAILS_STEP } from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.steps';
import { IModalWithData } from 'shared/Modals/types';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { PARTNERS_STEP } from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.steps';
import { StyledConstituentModalLabel } from 'pages/Instruments/components/Form/EtpDetails/components/ConstituentStep/components/ConstituentStep.styles';
import { TokenGeneralDetailsDifferencesSection } from 'shared/Modals/TouchedProductModal/TokenTouchedDataModal/ActiveTokenDifferencesSections/GeneralDetailsDifferencesSection';
import { TokenPartnerDetailsDifferencesSection } from './ActiveTokenDifferencesSections/TokenPartnerDetailsDifferencesSection';
import { createNotification } from 'store/notifications/actions';
import { diffFields } from 'pages/Instruments/helpers/getDiffFields';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { useSaveToken } from 'hooks/useTokens';
import { useTokenContext } from 'contexts/tokens';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { Box, Stack } from '@mui/material';

export interface DirtyField {
  propName: string;
  propLabel: string;
  prevValue: string;
  currentValue: string;
}

export const TokenTouchedDataModal = ({ closeModal, data, onCloseModalAction }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const { deleteUpdatedField, saveTokenToRemoteAndContext } = useSaveToken();
  const { currentToken, tokenFormsData, tokenActiveStep, tokenToFormData } = useTokenContext();
  const activeStep = (tokenActiveStep ?? GENERAL_DETAILS_STEP) as
    | typeof GENERAL_DETAILS_STEP
    | typeof PARTNERS_STEP;

  const targetStep = data.custom?.targetStep as typeof GENERAL_DETAILS_STEP | typeof PARTNERS_STEP;
  const initialStepFormData = tokenToFormData(currentToken)[tokenActiveStep];
  const formDataDirtyFields = diffFields(initialStepFormData, tokenFormsData[tokenActiveStep]);

  const saveValues = async () => {
    return saveTokenToRemoteAndContext({
      [activeStep]: tokenFormsData[activeStep],
    });
  };

  const onCancelButton = () => {
    closeModal();
  };

  const onSubmit = () => {
    saveValues()
      .then(() => {
        if (onCloseModalAction) onCloseModalAction(targetStep);
        closeModal();
      })
      .catch((error) =>
        dispatch(createNotification(errorNotification(error.message, 'Error'), error))
      );
  };

  function getActiveStepDifferencesSection(
    activeStep: typeof GENERAL_DETAILS_STEP | typeof PARTNERS_STEP
  ) {
    switch (activeStep) {
      case GENERAL_DETAILS_STEP:
        return (
          <TokenGeneralDetailsDifferencesSection
            formDataDirtyFields={formDataDirtyFields}
            initialStepFormData={initialStepFormData}
            deleteUpdatedField={deleteUpdatedField}
          />
        );
      case PARTNERS_STEP:
        return (
          <TokenPartnerDetailsDifferencesSection
            formDataDirtyFields={formDataDirtyFields}
            initialStepFormData={initialStepFormData}
            deleteUpdatedField={deleteUpdatedField}
          />
        );
    }
  }

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        header={<CardHeaderTitleWithLabel label="Token" title="Update Active Token" />}
        body={
          <Stack padding={2}>
            <StyledConstituentModalLabel>
              {formDataDirtyFields.length
                ? 'You have made following changes within this step:'
                : 'You removed all the changes you have made, do you want to continue?'}
            </StyledConstituentModalLabel>
            {getActiveStepDifferencesSection(activeStep)}
          </Stack>
        }
        footer={
          <Box padding={2}>
            <MuiStyledModalFooterButtons>
              <Button variant="primary" onClick={onSubmit} fullWidth>
                {formDataDirtyFields.length ? 'Confirm and Save' : 'Confirm'}
              </Button>
              <Button variant="secondary" onClick={onCancelButton} fullWidth>
                Cancel
              </Button>
            </MuiStyledModalFooterButtons>
          </Box>
        }
        onClose={closeModal}
      ></Card>
    </CustomModal>
  );
};
