import { Button } from 'components/Button/Button';
import ContactsListsTab from 'pages/Admin/ContactsPage/Tabs/ContactsListsTab';
import ContactsTab from 'pages/Admin/ContactsPage/Tabs/ContactsTab';
import useAppModal from 'hooks/useAppModal';
import {
  CONTACTS_MANAGEMENT_TABS,
  CONTACTS_MANAGEMENT_TAB_VALUE,
  CONTACTS_TAB_VALUE,
} from 'utils/constants/contacts';
import { COMPANY_CONTACT_TYPE, Contact, ContactList } from 'utils/types/contacts';
import { CONTACT_LIST_MODAL, CONTACT_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { ReactComponent as ArrowDown } from 'assets/chevron-down.svg';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';
import { YourContactsTab } from 'pages/Admin/ContactsPage/Tabs/YourContactsTab';
import {
  getAllExternalContacts,
  getAllContacts,
  getAllPartnerContacts,
} from 'utils/constants/reactQueries';
import { privateRoutesUrls } from 'router/constants';
import { queryClient } from 'utils/api/queries/queryClient';
import { useContactsListsQuery } from 'pages/Admin/ContactsPage/hooks/api/useContactsListsQuery';
import { useMemo } from 'react';
import { useUrlParams } from 'hooks/useUrlParams';
import { useUserPermissions } from 'store/user/selectors';
import { apiUrls } from 'utils/constants/apiUrls';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { Card } from 'components/Card/Card';

const ContactsPage = () => {
  const openModal = useAppModal();
  const permissions = useUserPermissions();
  const shouldShowAllTabs = permissions?.isIssuer || permissions?.isSystemAdmin;
  const createInternalContact = permissions?.canEditContacts;
  const createExternalContact = permissions?.canEditExternalContacts;

  const { urlParams, setUrlParams, setUrlParamsWithDefaults } = useUrlParams(
    {
      tab: CONTACTS_MANAGEMENT_TAB_VALUE.LISTS,
      subtab: null,
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_ITEMS_PER_PAGE,
    },
    privateRoutesUrls.dashboardRoutes.adminContacts
  );

  /* 
  DISTRIBUTION LISTS TAB
  */
  const openContactListModal = (modalType: MODAL_ACTIONS, contactList?: ContactList) =>
    openModal(
      {
        modalName: CONTACT_LIST_MODAL,
        modalData: {
          type: modalType,
          data: contactList,
        },
      },
      {
        onCloseModalAction: () => refetch(),
      }
    );

  const { data: contactListsData, refetch } = useContactsListsQuery(urlParams, true);

  const createListButton = contactListsData?._actions?.create && (
    <StyledTableTopButton
      onClick={() => openContactListModal(MODAL_ACTIONS.CREATE)}
      type="button"
      data-qa-id="createNewContactButton"
      size="medium"
      key="toolbar-button"
    >
      <span>New Distribution List</span>
      <PlusIcon />
    </StyledTableTopButton>
  );

  /* 
  CONTACTS TAB
  */
  const queryKey = useMemo(() => {
    if (urlParams.subtab === CONTACTS_TAB_VALUE.EXTERNAL) return getAllExternalContacts;
    if (urlParams.subtab === CONTACTS_TAB_VALUE.YOUR) return getAllContacts;
    return getAllPartnerContacts;
  }, [urlParams.subtab]);

  const openContactModal = (
    type: MODAL_ACTIONS,
    apiUri: string,
    contact?: Contact,
    contactTypeFlow?: COMPANY_CONTACT_TYPE
  ) => {
    openModal(
      {
        modalName: CONTACT_MODAL,
        modalData: {
          data: {
            contact,
            contactTypeFlow: contactTypeFlow ?? COMPANY_CONTACT_TYPE.UNAFFILIATED,
            apiUri: apiUri,
          },
          type: type,
        },
      },
      {
        onCloseModalAction: () => queryClient.invalidateQueries({ queryKey: [queryKey] }),
      }
    );
  };

  const createContactButton = (
    <TablePopoverMenu
      id={'contactsPopover'}
      anchor={
        <StyledTableTopButton
          type="button"
          data-qa-id="createNewContactButton"
          size="medium"
          key="toolbar-button"
          disabled={!Boolean(createExternalContact || createExternalContact)}
        >
          <span>New Contact</span>
          <ArrowDown />
        </StyledTableTopButton>
      }
      anchorOrigin={{
        vertical: 'bottom',
      }}
      transformOrigin={{
        horizontal: 'center',
      }}
      disabled={!Boolean(createExternalContact || createExternalContact)}
    >
      <ul>
        {createInternalContact && (
          <Button
            data-qa-id="importContact"
            fullWidth
            variant="inverse"
            onClick={() => openContactModal(MODAL_ACTIONS.CREATE, apiUrls.createContact)}
          >
            New affiliated contact
          </Button>
        )}
        {createExternalContact && (
          <Button
            data-qa-id="addNewContact"
            fullWidth
            variant="inverse"
            onClick={() => openContactModal(MODAL_ACTIONS.CREATE, apiUrls.createExternalContact)}
          >
            New external contact
          </Button>
        )}
      </ul>
    </TablePopoverMenu>
  );
  const showAllTabsTabs = CONTACTS_MANAGEMENT_TABS.map((tab) => ({ name: tab[0], param: tab[1] }));
  return (
    <>
      {shouldShowAllTabs ? (
        <>
          <TitleArea
            title={'Contact Management'}
            actionButton={
              urlParams.tab === CONTACTS_MANAGEMENT_TAB_VALUE.LISTS
                ? createListButton
                : createContactButton
            }
            tabConfig={{
              tabs: showAllTabsTabs,
              setUrlParamsWithDefaults: (data) => {
                setUrlParamsWithDefaults(data);
              },
              active: urlParams.tab as CONTACTS_MANAGEMENT_TAB_VALUE,
            }}
          />
          {urlParams.tab === CONTACTS_MANAGEMENT_TAB_VALUE.LISTS && (
            <Card body={<ContactsListsTab urlParams={urlParams} setUrlParams={setUrlParams} />} />
          )}
          {urlParams.tab === CONTACTS_MANAGEMENT_TAB_VALUE.CONTACTS && (
            <Card
              body={
                <ContactsTab
                  urlParams={urlParams}
                  setUrlParams={setUrlParams}
                  setUrlParamsWithDefaults={setUrlParamsWithDefaults}
                />
              }
            />
          )}
        </>
      ) : (
        <YourContactsTab urlParams={urlParams} setUrlParams={setUrlParams} />
      )}
    </>
  );
};

export default ContactsPage;
