import { Theme } from '@mui/material/styles/createTheme';

export const blueChip = (theme: Theme) => {
  const chip = theme.customComponents.chip.blue;
  return [
    {
      props: (props: { variant: string }) => props.variant === 'blue',
      style: {
        backgroundColor: chip.backgroundColor,
        border: chip.border,
        svg: {
          fill: chip.color,
        },
        '& .MuiChip-label': {
          color: chip.color,
        },
        '& .MuiChip-icon': {
          color: chip.color,
        },
        '&:hover': {},
        '&:focus': {
          boxShadow: chip.focus?.boxShadow,
        },
      },
    },
  ];
};
