import { get } from 'lodash';
import { NavReviewItem, NavReviewItemsRows } from 'utils/types/nav';
import Table from 'components/Table';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import {
  NavReviewDifferencesColumnConfig,
  NavReviewDifferencesColumnConfigFA,
} from './NavReviewDifferencesColumnConfig';
import { PortfolioCompositionStatus } from 'utils/types/pcfs';
import { Button } from 'components/Button/Button';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { Stack } from '@mui/material';
import InputComponent from 'components/Input';
import Link from 'components/Link';
import { StyledTextAreaComponent } from 'shared/Modals/NavReviewModals/NavReviewModal.styles';
import { MuiStyledClosingNavTableRow } from 'pages/Ledger/NavReviewPage/components/styles';
import { NavReviewDifferencesTableRow } from './NavReviewDifferencesTableRow';
import { NavModalTransactions } from 'shared/Modals/NavReviewModals/hook/useNavReviewModalData';
import { useUserPermissions } from 'store/user/selectors';

export interface NavReviewTableProps {
  hasError: boolean;
  hasOpenOrders?: boolean;
  loading: boolean;
  navReviewItem: NavReviewItem;
  navTransactions: NavModalTransactions;
  onRebook: () => void;
  onRebookEstimation: (val?: string | number) => void;
  onRefreshOnyxData: () => void;
  overrideBalance: string | number;
  setOverrideBalance: (val: string | number) => void;
  tableRows: NavReviewItemsRows[];
}

function NavReviewDifferencesTable({
  hasError,
  hasOpenOrders,
  loading,
  navReviewItem,
  navTransactions,
  onRebook,
  onRebookEstimation,
  onRefreshOnyxData,
  overrideBalance,
  setOverrideBalance,
  tableRows,
}: NavReviewTableProps) {
  const user = useUserPermissions();
  const updateNavLedgerBalance = (value: string | number) => {
    const regex = /^[+-.]$/;
    if (!regex.test(value as string) && value !== navReviewItem.ledgerBalance) {
      setOverrideBalance(value);
    }
  };

  const rebookReasons = get(
    navTransactions,
    NavReviewItemsRows.REBOOK_BALANCE_ADJUST
  )?.transactions?.find((transaction) => transaction.onyx.reason)?.onyx.reason;

  const openingNavTableRow = (
    <MuiStyledTableRow
      data-qa-id={`${NavReviewItemsRows.OPENING_NAV}-row`}
      key={NavReviewItemsRows.OPENING_NAV}
    >
      <MuiStyledTableCell data-qa-id="nav-row-name">
        {NavReviewItemsRows.OPENING_NAV}
      </MuiStyledTableCell>

      <MuiStyledTableCell data-qa-id="onyx-ledger-balance">
        {navReviewItem.totals?.openBalance}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id="onyx-fund-accountant-balance">
        {navReviewItem.fundAccountantTotals?.openBalance}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id="onyx-difference">-</MuiStyledTableCell>
    </MuiStyledTableRow>
  );

  const openingNavTableRowFA = (
    <MuiStyledTableRow
      data-qa-id={`${NavReviewItemsRows.OPENING_NAV}-row`}
      key={NavReviewItemsRows.OPENING_NAV}
    >
      <MuiStyledTableCell data-qa-id="nav-row-name">
        {NavReviewItemsRows.OPENING_NAV}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id="onyx-fund-accountant-balance">
        {navReviewItem.fundAccountantTotals?.openBalance}
      </MuiStyledTableCell>
    </MuiStyledTableRow>
  );

  const closingNavTableRow = (
    <MuiStyledClosingNavTableRow>
      <MuiStyledTableCell>Closing NAV</MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id="onyx-fund-accountant-balance">
        {hasError ? (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link
              text={'Override'}
              onClick={() => {
                setOverrideBalance(navReviewItem.fundAccountantBalance);
                onRebookEstimation(navReviewItem.fundAccountantBalance);
              }}
            />
            <InputComponent
              type="number"
              value={overrideBalance}
              onChange={({ target }) => {
                updateNavLedgerBalance(target.value);
              }}
              onBlur={() => onRebookEstimation()}
              onKeyDown={({ key }) => {
                if (key === 'Enter') onRebookEstimation();
              }}
            />
          </Stack>
        ) : (
          navReviewItem.ledgerBalance
        )}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id="onyx-fund-accountant-balance">
        {navReviewItem.fundAccountantBalance}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id="onyx-difference">
        {navReviewItem.difference}
      </MuiStyledTableCell>
    </MuiStyledClosingNavTableRow>
  );

  const closingNavTableRowFA = (
    <MuiStyledClosingNavTableRow>
      <MuiStyledTableCell>Closing NAV</MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id="onyx-fund-accountant-balance">
        {navReviewItem.fundAccountantBalance}
      </MuiStyledTableCell>
    </MuiStyledClosingNavTableRow>
  );

  const tableButtons = (
    <>
      {navReviewItem.status !== PortfolioCompositionStatus.APPROVED && (
        <Button
          data-qa-id="refreshOnyxData"
          disabled={loading}
          fullWidth
          type="button"
          variant="secondary"
          onClick={() => {
            onRefreshOnyxData();
          }}
        >
          Refresh Onyx Data
        </Button>
      )}
      {hasError && (
        <Button
          data-qa-id="rebook"
          disabled={loading || !navReviewItem._actions?.rebook || hasOpenOrders}
          fullWidth
          type="button"
          variant="primary"
          onClick={() => {
            onRebook();
          }}
        >
          Rebook
        </Button>
      )}
    </>
  );

  return (
    <>
      <Table noPadding>
        <TableHeaderWithMultiSort
          columns={
            user?.isFundAccount
              ? NavReviewDifferencesColumnConfigFA
              : NavReviewDifferencesColumnConfig
          }
        />
        <TableBodyWithStates
          loadingData={loading}
          hasContent={tableRows.length > 0}
          noContentLabel="No data."
        >
          <>
            {!user?.isFundAccount ? openingNavTableRow : openingNavTableRowFA}
            {tableRows.map((navReviewRow, index) => (
              <NavReviewDifferencesTableRow
                key={index}
                rowKey={navReviewRow}
                differenceItem={get(navTransactions, navReviewRow)}
              />
            ))}
            {!user?.isFundAccount ? closingNavTableRow : closingNavTableRowFA}
          </>
        </TableBodyWithStates>
      </Table>
      <Stack mt={2} direction="row" justifyContent="end">
        <Stack spacing={2} alignItems="center" direction="row" width="50%">
          {!user?.isFundAccount && tableButtons}
        </Stack>
      </Stack>
      {rebookReasons && (
        <StyledTextAreaComponent
          value={rebookReasons}
          placeholder="Enter notes (optional)"
          label={'Reconciliation notes'}
          disabled
        />
      )}
    </>
  );
}

export default NavReviewDifferencesTable;
