import { CustodianAccount } from 'utils/types';
import { Button } from 'components/Button/Button';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { CustodianStatus } from 'utils/constants/custodians';
import { MuiStyledActionsTableCell } from 'shared/Tables/table.styles';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';

export type CustodianTableRowProps = {
  custodian: CustodianAccount;
  editAction: () => void;
  deleteAction: () => void;
  activateAction: () => void;
};

export const CustodianTableRow = ({
  custodian,
  editAction,
  deleteAction,
  activateAction,
}: CustodianTableRowProps) => {
  return (
    <MuiStyledTableRow
      key={custodian.name}
      onClick={() => {
        custodian?.status !== CustodianStatus.DEACTIVATED && editAction();
      }}
    >
      <MuiStyledTableCell>{custodian.provider}</MuiStyledTableCell>
      <MuiStyledTableCell>{custodian.name}</MuiStyledTableCell>
      <MuiStyledTableCell>{custodian.description}</MuiStyledTableCell>
      <MuiStyledActionsTableCell
        align="right"
        onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
          event.stopPropagation()
        }
      >
        <TablePopoverMenu id={custodian._id} className="actionsCell">
          {custodian?.status !== CustodianStatus.ACTIVE && (
            <Button
              data-qa-id="resendInvitation"
              fullWidth
              variant="inverse"
              onClick={activateAction}
            >
              Activate account
            </Button>
          )}
          {custodian?.status !== CustodianStatus.DEACTIVATED && (
            <Button data-qa-id="deleteUser" fullWidth variant="inverse" onClick={deleteAction}>
              Deactivate account
            </Button>
          )}
        </TablePopoverMenu>
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};
