import { Button } from 'components/Button/Button';
import ExchangeRow from 'pages/Instruments/components/Form/EtpDetails/components/ExchangeStep/components/ProductExchangeRow';
import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import isEmpty from 'lodash/isEmpty';
import useAppModal from 'hooks/useAppModal';
import { Exchange, ExchangeList } from 'utils/types';
import {
  ExchangeLocalTickerType,
  ExchangeType,
  ExchangesProps,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { MODAL_ACTIONS, PRODUCT_EXCHANGE_MODAL } from 'shared/Modals/constants';
import { ProductExchangesColumnConfig } from './tableColumnsConfig';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { useUserPermissions } from 'store/user/selectors';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { Grid } from '@mui/material';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';

interface ProductExchangeStepTableProps {
  productExchangesFormData: ExchangesProps;
  exchanges: Exchange[];
  allExchanges: ExchangeList;
  updateProductExchanges: (productExchangesFormData: ExchangesProps) => void;
  loading: boolean;
}

export const ProductExchangeStepTable = ({
  exchanges,
  productExchangesFormData,
  updateProductExchanges,
  loading,
}: ProductExchangeStepTableProps) => {
  const permissions = useUserPermissions();
  const openModal = useAppModal();

  const removeExchangeTicker = (productExchange: ExchangeType) => {
    const updatedExchanges = productExchangesFormData.map((el) => {
      if (el.id === productExchange.id) {
        return productExchange;
      } else {
        return { ...el };
      }
    });
    updateProductExchanges(updatedExchanges);
  };

  const openEditProductExchangeModal = (
    type: MODAL_ACTIONS,
    productExchangeFormData?: ExchangeType,
    ticker?: ExchangeLocalTickerType
  ) => {
    openModal({
      modalName: PRODUCT_EXCHANGE_MODAL,
      modalData: {
        data: {
          exchanges: exchanges,
          exchangeFormData: productExchangeFormData,
          localTicker: ticker,
        },
        type: type,
      },
    });
  };

  const openDeleteProductExchangeModal = (productExchange?: ExchangeType) => {
    openModal({
      modalName: PRODUCT_EXCHANGE_MODAL,
      modalData: {
        data: {
          exchangeFormData: productExchange,
        },
        type: MODAL_ACTIONS.DELETE,
      },
    });
  };

  const toolbarButton = permissions?.canEditEtpExchange ? (
    <Button
      variant="secondary"
      onClick={() => openEditProductExchangeModal(MODAL_ACTIONS.CREATE)}
      type="button"
      size="medium"
      sx={{ '& span': { color: 'white' } }}
    >
      <span>Add Exchange</span>
      <PlusIcon />
    </Button>
  ) : null;

  return (
    <Table
      toolbarTools={[
        <>
          <Grid marginTop={1} marginBottom={1} container display={'flex'} alignItems={'center'}>
            <Grid item xs={12} md={6}>
              <ProductConfigHeader title="Exchanges" />
            </Grid>
            <Grid item xs={12} md={6} display={'flex'} justifyContent={'flex-end'}>
              {toolbarButton}
            </Grid>
          </Grid>
        </>,
      ]}
    >
      <TableHeaderWithMultiSort columns={ProductExchangesColumnConfig} />
      <TableBodyWithStates
        loadingData={Boolean(loading)}
        hasContent={!isEmpty(productExchangesFormData)}
        noContentLabel="No exchanges at the moment."
      >
        {productExchangesFormData?.map((productExchange, index) => (
          <ExchangeRow
            key={index + productExchange.id}
            productExchange={productExchange}
            handleEditProductExchange={openEditProductExchangeModal}
            handleEditProductExchangeTicker={openEditProductExchangeModal}
            handleRemoveProductExchangeTicker={removeExchangeTicker}
            handleRemoveProductExchange={openDeleteProductExchangeModal}
          />
        ))}
      </TableBodyWithStates>
    </Table>
  );
};
