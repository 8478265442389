import { Button } from 'components/Button/Button';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { ProductCustodianWallet } from 'utils/types/wallets';
import { CompanySuggestionDto } from 'utils/types';
import { StyledActionsTableCell } from 'shared/Tables/table.styles';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';
import { MuiStyledTableCell } from 'components/Table/TableCell';

type Props = {
  wallet: ProductCustodianWallet;
  custodianName: string;
  partners: CompanySuggestionDto[];
  editAction: () => void;
  deleteAction: () => void;
  activateAction: () => void;
};

export const TokenWalletsTableRow = ({
  wallet,
  partners,
  custodianName,
  editAction,
  deleteAction,
  activateAction,
}: Props) => {
  return (
    <MuiStyledTableRow
      key={wallet._id}
      onClick={() => {
        wallet?._actions?.update && editAction();
      }}
    >
      <MuiStyledTableCell>{wallet.description} </MuiStyledTableCell>
      <MuiStyledTableCell>{wallet.chain}</MuiStyledTableCell>
      <MuiStyledTableCell>{custodianName}</MuiStyledTableCell>
      <MuiStyledTableCell>
        {partners.find((partner) => partner._id === wallet.transactingCompany)?.name}
      </MuiStyledTableCell>
      <StyledActionsTableCell
        align="right"
        noMinWidth
        onClick={(event) => {
          event?.stopPropagation();
        }}
      >
        {wallet?._actions && Object.keys(wallet?._actions) && (
          <TablePopoverMenu id={wallet._id ?? ''} className="actionsCell">
            {wallet?._actions?.activate && (
              <Button
                data-qa-id="activateWallet"
                fullWidth
                variant="inverse"
                onClick={activateAction}
              >
                Activate wallet
              </Button>
            )}
            {wallet?._actions?.delete && (
              <Button data-qa-id="deleteWallet" fullWidth variant="inverse" onClick={deleteAction}>
                Deactivate wallet
              </Button>
            )}
          </TablePopoverMenu>
        )}
      </StyledActionsTableCell>
    </MuiStyledTableRow>
  );
};
