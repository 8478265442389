import { Button } from 'components/Button/Button';
import useAppModal from 'hooks/useAppModal';
import { MODAL_ACTIONS, WALLET_MODAL } from 'shared/Modals/constants';
import { ReactComponent as ArrowDown } from 'assets/chevron-down.svg';
import { StyledPageHeader } from './WalletsStep.styles';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';
import { WALLETS_TABS_STATUS } from 'utils/constants/wallets';
import { WalletUnionType, WalletType, CustodianWalletType } from 'utils/types/wallets';
import { WalletsStepTables } from './components/WalletsStepTables';
import { WalletsStepTabs } from './components/WalletsStepTabs';
import { useCallback, useMemo, useState } from 'react';
import { useCompanyQuery } from 'pages/Partners/hooks/useCompanyQuery';
import { useParams } from 'react-router-dom';
import { queryClient } from 'utils/api/queries/queryClient';
import { getWalletsPerCompanyQuery } from 'utils/constants/reactQueries';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import { Stack } from '@mui/material';

export const WalletsStep = () => {
  const { id: partnerId } = useParams();
  const { data: partnerData } = useCompanyQuery(partnerId ?? '');
  const companyID = partnerData?._id ?? '';
  const canAddCustodianWallet = partnerData?._actions?.addCustodianWallet;
  const canAddOwnWallet = partnerData?._actions?.addOwnWallet;

  const openModal = useAppModal();
  const [activeTab, setActiveTab] = useState(WALLETS_TABS_STATUS.ACTIVE);
  const handleChangeTab = (tab: WALLETS_TABS_STATUS) => {
    setActiveTab(tab);
  };

  const openWalletModal = useCallback(
    (
      type: MODAL_ACTIONS,
      walletType: WalletType,
      wallet?: WalletUnionType,
      custodianWalletType?: CustodianWalletType
    ) => {
      openModal(
        {
          modalName: WALLET_MODAL,
          modalData: {
            data: { wallet, type: walletType, custodianWalletType: custodianWalletType },
            companyData: {
              id: companyID,
              name: partnerData?.name,
            },
            type: type,
            custom: {
              isToken: partnerData?.type === 'AUTHORIZED_MERCHANT' ? true : false,
            },
          },
        },
        {
          onCloseModalAction: () => {
            queryClient.invalidateQueries(getWalletsPerCompanyQuery + walletType);
          },
        }
      );
    },
    [companyID, openModal, partnerData?.name]
  );

  const createWalletButton = useMemo(
    () => (
      <TablePopoverMenu
        id={'walletsPopover'}
        anchor={
          <StyledTableTopButton
            type="button"
            data-qa-id="createNewWalletButton"
            size="medium"
            key="toolbar-button"
            disabled={!Boolean(canAddCustodianWallet && canAddOwnWallet)}
            variant="secondary"
          >
            <span>Add Wallet</span>
            <ArrowDown />
          </StyledTableTopButton>
        }
        anchorOrigin={{
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
        }}
      >
        <Stack padding={1} gap={1}>
          <Button
            data-qa-id="newCustodianWallet"
            fullWidth
            variant="inverse"
            onClick={() =>
              openWalletModal(
                MODAL_ACTIONS.CREATE,
                WalletType.CUSTODIAN_WALLET,
                undefined,
                CustodianWalletType.CONSTITUENT
              )
            }
          >
            <span>Create Issuer Constituent Wallet</span>
          </Button>
          <Button
            data-qa-id="newCustodianWallet"
            fullWidth
            variant="inverse"
            onClick={() =>
              openWalletModal(
                MODAL_ACTIONS.CREATE,
                WalletType.CUSTODIAN_WALLET,
                undefined,
                CustodianWalletType.DELIVERY
              )
            }
          >
            <span>Create Issuer Delivery Wallet</span>
          </Button>
          <Button
            data-qa-id="newCompanyWallet"
            fullWidth
            variant="inverse"
            onClick={() => openWalletModal(MODAL_ACTIONS.CREATE, WalletType.OWN_WALLET)}
          >
            <span>Create {partnerData?.name} Wallet</span>
          </Button>
        </Stack>
      </TablePopoverMenu>
    ),
    [canAddCustodianWallet, canAddOwnWallet, openWalletModal, partnerData?.name]
  );

  return (
    <>
      <StyledPageHeader>
        <ProductConfigHeader title="Wallets" />
        {createWalletButton}
      </StyledPageHeader>
      <WalletsStepTabs active={activeTab} onChangeTab={handleChangeTab}>
        <WalletsStepTables walletsStatus={activeTab} />
      </WalletsStepTabs>
    </>
  );
};
