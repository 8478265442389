import Modal from 'styled-react-modal';
import SearchInput from 'components/Search';
import { Row } from 'components/Grid';
import { styled as MuiStyled, Grid, Box } from '@mui/material';
import fontTypes from 'utils/theme/designTokens/fontTypes';
import MultiSelect from 'components/MultiSelect/MultiSelect';

export const TableCellContent = MuiStyled(Box)`
  display: flex;
  gap: 8px;
`;

export const StyledToolbarsWrapper = MuiStyled(Box)`
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: row-reverse;
`;

export const StyledToollbarRow = MuiStyled(Row)`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  button {
    height: 40px;
  }
`;

export const StyledToollbarTopRow = MuiStyled(Row)`
  justify-content: end;
  gap: 8px;
`;

export const StyledBottomRowRight = MuiStyled(Row)`
  flex-wrap: nowrap;
  gap: 8px;
  width: auto;
`;

export const MultiSelectFilter = MuiStyled(MultiSelect)`
  border: none;
  min-width: 128px;
  button {
    outline: 0;
  }
`;

export const StyledContactsModal = Modal.styled`
  width: 700px;
  padding: 0;
`;

export const StyledContactsContainer = MuiStyled(Box)`
  display: grid;
  grid-template-columns: repeat(2, minmax(45%, 1fr));
  gap: ${({ theme }) => `${theme.spacers['spacing-08']} ${theme.spacers['spacing-16']}`};
  width: 100%;
`;

export const StyledContactSelector = MuiStyled(Box)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.tertiary.lighter};
  display: flex;
  height: 56px;
  padding: 8px 16px;
  :hover,
  :active,
  :focus {
    background-color: #D9D9D9;
  }

  & > :nth-child(3) {
    margin: auto 0 auto auto;
  }
`;

export const StyledContactName = MuiStyled(Box)`
  ${() => fontTypes['body-01']};
  color: ${({ theme }) => theme.palette.secondary.main};
  display: block;
`;

export const StyledInfoWrapper = MuiStyled(Box)`
  max-width: calc(100% - 20px);
`;

export const StyledContactEmail = MuiStyled(Box)`
  ${() => fontTypes['caption-01']};
  color: ${({ theme }) => theme.palette.secondary.light};
  display: block;
  max-width: 100%;
  overflow: hidden;
`;

export const StyledSearchContact = MuiStyled(SearchInput)`
  width: 240px;
`;

export const StyledShowMoreContainer = MuiStyled(Box)`
  padding: ${({ theme }) => theme.spacers['spacing-24']};
  display: grid;
  gap: 16px;
`;

export const StyledShowMoreTitle = MuiStyled('span')`
  ${() => fontTypes['label-01']};
  color: ${({ theme }) => theme.palette.secondary.light};
`;

export const StyledShowMoreListElement = MuiStyled('li')`
  ${() => fontTypes['body-01']};
  padding: ${({ theme }) => `${theme.spacers['spacing-08']} 0`};
`;

export const LoadingWrapper = MuiStyled(Box)`
  display: flex;
  justify-content: center;
  margin-block: ${({ theme }) => theme.spacers['spacing-24']};
`;

export const StyledMultiSelectFilter = MuiStyled(MultiSelectFilter)`
  min-width: 152px;
`;

export const MuiStyledContactsContainer = MuiStyled(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(45%, 1fr))',
  gap: theme.spacing(2),
  padding: 0,
  width: '100%',
  margin: 0,
}));

export const MuiStyledContactSelector = MuiStyled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.grey[100],
  height: 56,
  padding: theme.spacing(1, 2),
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

export const MuiStyledContactName = MuiStyled(Box)(({ theme }) => ({
  ...theme.typography.bodyMedium,
  color: theme.palette.text.primary,
}));

export const MuiStyledContactEmail = MuiStyled(Box)(({ theme }) => ({
  ...theme.typography.captionMedium,
  color: theme.palette.text.secondary,
}));

export const MuiStyledSearchContact = MuiStyled(SearchInput)(({ theme }) => ({
  width: 240,
  padding: 0,
}));
