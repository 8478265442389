import { ChipProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { defaultChip } from './variants/defaultChip';
import { greenChip } from './variants/greenChip';
import { redChip } from './variants/redChip';
import { blueChip } from './variants/blueChip';
import { yellowChip } from './variants/yellowChip';
import { inverseChip } from './variants/inverseChip';
import { neutral } from 'theme/colors/baseColors';

export function chip(theme: Theme) {
  return {
    MuiChip: {
      variants: [
        ...defaultChip(theme),
        ...greenChip(theme),
        ...redChip(theme),
        ...blueChip(theme),
        ...yellowChip(theme),
        ...inverseChip(theme),
      ],
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: ({ ownerState }: { ownerState: ChipProps & { expandable?: boolean } }) => {
          const smallSize = ownerState.size === 'small';
          const mediumSize = ownerState.size === 'medium';

          const size = {
            ...(smallSize && {
              height: 24,
            }),
            ...(mediumSize && {
              height: 32,
            }),
          };
          return {
            ...size,
            borderRadius: '6px',
            display: 'inline-flex',
            padding: '6px 8px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            boxShadow: 'none',
            '& .MuiChip-label': {
              padding: 0,
              fontFamily: 'Inter',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '100%',
            },
            '& .MuiChip-deleteIcon': {
              margin: 0,
            },
            '&.Mui-disabled': {
              backgroundColor: neutral[20],
              borderBottom: `1px solid ${neutral[30]}`,
              '& .MuiChip-label': {
                color: neutral[60],
              },
              '& .MuiChip-icon': {
                color: neutral[60],
              },
            },
          };
        },
      },
    },
  };
}
