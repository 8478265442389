import { Theme } from '@mui/material/styles/createTheme';

export const inverseChip = (theme: Theme) => {
  const chip = theme.customComponents.chip.inverse;

  return [
    {
      props: (props: { variant: string }) => props.variant === 'inverse',
      style: {
        backgroundColor: chip.backgroundColor,
        border: chip.border,
        svg: {
          fill: chip.color,
        },
        '& .MuiChip-label': {
          color: chip.color,
        },
        '& .MuiChip-icon': {
          color: chip.color,
        },
        '&:hover': {
          backgroundColor: chip.hover?.backgroundColor,
        },
        '&:focus': {
          backgroundColor: chip.backgroundColor,
          boxShadow: chip.focus?.boxShadow,
        },
      },
    },
  ];
};
