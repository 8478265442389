import React, { ComponentPropsWithoutRef, Fragment } from 'react';
import { Autocomplete, Checkbox, FormControl, FormLabel, TextField } from '@mui/material';
import {
  GroupedOptionsType,
  OptionType,
  OptionValueType,
  OnChangeType,
} from 'components/Select/Select.types';
import transformOptions from 'components/Select/helpers/transformOptions';
import Tag from 'components/Tag/Tag';

export interface MultiSelectProps<T extends OptionValueType>
  extends Omit<ComponentPropsWithoutRef<'select'>, 'onChange' | 'value' | 'size'> {
  ['data-qa-id']?: string;
  ['data-qa-options-id']?: string;
  errorMessage?: string;
  filteredOptions?: OptionType<T>[] | GroupedOptionsType<T>;
  fullWidth?: boolean;
  isInvalid?: boolean;
  label?: string;
  minWidth?: string | number;
  name?: string;
  onChange?: OnChangeType<T>;
  onFilter?: (inputValue?: string) => void;
  options?: OptionType<T>[] | GroupedOptionsType<T>;
  showMaxValues?: number;
  size?: 'small' | 'medium' | 'large';
  value?: string[];
  variantOptions?: 'chip' | 'li';
  width?: string;
  withFilter?: boolean;
}

export const MultiSelect = <T extends OptionValueType>({
  'data-qa-id': dataQaId,
  'data-qa-options-id': dataQaOptionsId,
  className,
  disabled,
  errorMessage,
  fullWidth = false,
  isInvalid,
  label,
  minWidth = '200px',
  name,
  onChange,
  options,
  placeholder,
  showMaxValues = 3,
  size = 'medium',
  value = [],
  variantOptions = 'li',
  width,
  ...props
}: MultiSelectProps<T>) => {
  const items = transformOptions(options);
  //const newTagSize = size === 'small' ? 'small' : 'medium';
  const selectedItems = items.filter((item) => {
    return item.value !== undefined && value.some((v) => v !== undefined && v === item.value);
  });

  const handleOnChange = (event: React.SyntheticEvent, selectedOptions: OptionType<T>[]) => {
    const selectedValues = selectedOptions.map((option) => option.value as T);
    const primarySelection = selectedValues[0];
    onChange && onChange(primarySelection, selectedValues);
  };

  const handleOnOptionSelect = (
    event: React.MouseEvent,
    option: OptionType<T>,
    selected: boolean
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const newSelectedItems = selected
      ? selectedItems.filter((item) => item.value !== option.value)
      : [...selectedItems, option];
    handleOnChange(event, newSelectedItems);
  };

  const isOptionEqualToValue = (option: OptionType<T>, value: OptionType<T>) => {
    return option.value === value.value;
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={isInvalid}
      data-qa-id={dataQaId}
      disabled={disabled}
    >
      {label && <FormLabel sx={{ marginBottom: '4px' }}>{label}</FormLabel>}
      <Autocomplete
        size={size}
        data-qa-id={dataQaId}
        multiple
        disabled={disabled}
        id="checkboxes-tags-demo"
        options={items}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        value={selectedItems}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={handleOnChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            placeholder={value.length ? '' : placeholder}
            InputProps={{
              ...params.InputProps,
              sx: {
                padding: '4px',
              },
            }}
          />
        )}
        renderOption={(props, option, { selected }) => {
          const { onClick, ...optionProps } = props;
          return variantOptions === 'chip' ? (
            <Tag
              label={option.label}
              onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
                handleOnOptionSelect(event, option, selected)
              }
              size="medium"
              sx={{
                m: 0.5,
                backgroundColor: selected ? 'primary.main' : 'action.selected',
                color: selected ? 'primary.contrastText' : 'text.primary',
                '&:hover': {
                  backgroundColor: selected ? 'primary.dark' : 'action.hover',
                },
              }}
            />
          ) : (
            <li
              {...optionProps}
              onClick={(event) => handleOnOptionSelect(event, option, selected)}
              data-qa-options-id={dataQaOptionsId}
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option.label}
            </li>
          );
        }}
        renderTags={(value: OptionType<T>[], getTagProps) => (
          <Fragment>
            {value.slice(0, showMaxValues).map((option, index) => (
              <Tag label={option.label} {...getTagProps({ index })} size="medium" />
            ))}
            {showMaxValues && value.length > showMaxValues && (
              <Tag label={`+${value.length - showMaxValues}`} variant="default" size="small" />
            )}
          </Fragment>
        )}
        sx={{
          width: fullWidth ? '100%' : undefined,
          minWidth: minWidth,
        }}
      />
    </FormControl>
  );
};

export default MultiSelect;
